import React from "react";
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import cardBg from "../../assets/images/shapes/contact-card-bg-1-1.png";

const ContactCardCarousel = () => {
  const swiperOptions = {
    spaceBetween: 30,
    slidesPerView: 3,
	  autoplay: {
          delay: 1000,
        },
	  navigation: {
        nextEl: ".swiper-button-next", // arrows on the side of the slides
        prevEl: ".swiper-button-prev", // arrows on the side of the slides
      },
    breakpoints: {
      0: {
        spaceBetween: 0,
        slidesPerView: 1
      },
      480: {
        spaceBetween: 0,
        slidesPerView: 1
      },
      767: {
        spaceBetween: 30,
        slidesPerView: 2
      },
      1199: {
        spaceBetween: 30,
        slidesPerView: 3
      }
    }
  };
  return (
    <div className="grabbable contact-card-carousel ">
      <div className="container">
        <Swiper {...swiperOptions}
	  >
          <SwiperSlide>
	  
            <div
              className=" contact-card d-flex flex-column text-center justify-content-center align-items-center background-secondary"
              style={{ backgroundImage: `url(${cardBg})` }}
            >
              <i class="fas fa-map-marked-alt"></i>
              <h3 className="title-kontakt">Adres</h3>
              <p className="tekst-kontakt">
                41-215 Sosnowiec - Kazimerz  <br />ul. Morcinka 17
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="kontakt-blue contact-card d-flex flex-column text-center justify-content-center align-items-center background-primary"
              style={{ backgroundImage: `url(${cardBg})` }}
            >
              <i class="fas fa-phone"></i>
              <h3 className="title-kontakt-green">Telefony</h3>
              <p className="tekst-kontakt-white ">
               <a href="tel:322927145">32 292 71 45</a><br />
<a href="tel:+48501483860">+48 501 483 860</a>

              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="contact-card d-flex flex-column text-center justify-content-center align-items-center background-special"
              style={{ backgroundImage: `url(${cardBg})` }}
            >
               <i class="fas fa-envelope"></i>
              <h3 className="title-kontakt">E-mail / Godziny otwarcia</h3>
              <p className="tekst-kontakt">
                <a href="mailto:kontakt@serwisjatkowski.pl">kontakt@serwisjatkowski.pl</a><br/>
Poniedziałek - Piątek: 8:00 - 17:00
               
              </p>
            </div>
          </SwiperSlide>

        </Swiper>
        
      </div>
    </div>
  );
};

export default ContactCardCarousel;
