import React from "react";

const GoogleMap = ({ extraClass }) => {
  return (
    <div className={`google-map__${extraClass}`}>
      <iframe
        title="template google map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10197.303281252178!2d19.2294779!3d50.2858456!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe655d046c887d8a7!2sAuto%20Serwis%20Jatkowski%20Tomasz!5e0!3m2!1spl!2spl!4v1617274229313!5m2!1spl!2spl"
        className={`map__${extraClass}`}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default GoogleMap;