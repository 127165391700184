import React from "react";
import { Link } from "gatsby";
import { Container } from "react-bootstrap";
import NavLinks from "./nav-links";

import logoDark from "../../assets/images/logo-dark.png";

const HeaderTwo = () => {
  return (
    <div className="main-header__two">
      <div className="main-header__top">
        <Container>
          <p><i class="fas fa-clock"></i> Poniedziałek - Piątek: 8:00 - 17:00</p>
         {/* <div className="main-header__social">
          <a href="#none" aria-label="twitter">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#none" aria-label="facebook">
              <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none" aria-label="pinterest">
              <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none" aria-label="instagram">
              <i className="fab fa-instagram"></i>
            </a> 
          </div>*/}
        </Container>
      </div>
      <div className="header-upper">
        <Container>
          <div className="logo-box">
            <Link to="/">
              <img className="logo-head" src={logoDark} alt="" />
            </Link>
            <span className="fa fa-bars mobile-nav__toggler"></span>
          </div>

          <div className="header-info">
            <div className="header-info__box">
	    <i class="fas fa-map-marked-alt"></i>
             
              <div className="header-info__box-content">
	  
	  <h3>Adres</h3>
                <p>Sosnowiec - Kazimerz, ul. Morcinka 17</p>
                
              </div>
            </div>
            <div className="header-info__box">
              <i class="fas fa-mobile"></i>
              <div className="header-info__box-content">
                <h3>Telefon</h3>
                <p>
                  <a href="tel:+48501483860">+48 501 483 860</a>
                </p>
              </div>
            </div>
            <div className="header-info__box">
              <i class="fas fa-envelope"></i>
              <div className="header-info__box-content">
              
	  <h3>Email</h3>
                <p>
                  <a href="mailto:kontakt@serwisjatkowski.pl">kontakt@serwisjatkowski.pl</a></p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <nav className="main-menu">
        <Container>
          <NavLinks extraClassName="dynamic-radius" />
          <Link className="thm-btn dynamic-radius" to="/contact">
            Zobacz gdzie jesteśmy
          </Link>
        </Container>
      </nav>
    </div>
  );
};

export default HeaderTwo;
